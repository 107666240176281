import { Dispatch } from "redux";
import { AppAnalytics } from "src/helpers";
export const SET_REGION = "SET_REGION";

export const fetchGeoInfo = () => {
  return async (dispatch: Dispatch) => {
    try {
      const websiteurl = process?.env?.REACT_APP_WEBSITE_URL || "";
      const res = await fetch(websiteurl.concat("api/geoinfo"));
      const geoInfo = await res.json();

      dispatch({
        type: SET_REGION,
        payload: geoInfo?.country ? geoInfo?.suggestedServerRegion : "in",
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SET_REGION,
        payload: "in",
      });
    }
  };
};

export const setRegionInStore = (region: string) => {
  AppAnalytics.track("btn.clicked", {
    btnId: "server.region.changed",
    region: region,
  });
  return async (dispatch: Dispatch) => {
    dispatch({ type: SET_REGION, payload: region });
  };
};
